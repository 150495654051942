<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { Bar } from 'vue-chartjs/legacy';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';
import moment from 'moment-timezone';


ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  name: 'BarChart',
  components: { Bar },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    width: {
      type: Number,
      default: 300,
    },
    height: {
      type: Number,
      default: 200,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        responsive: true,
      },
    };
  },


  computed: {
    ...mapGetters(['getFiliales', 'getDailyReport']),
  },
  watch: {
    getDailyReport: {
      immediate: true, // run the handler immediately after the watcher is created
      handler() {
        if (this.getDailyReport) {
          this.updateChartDataLabels();
          this.updateChartDataDataSet();
        }
      },
    },
  },

  created() {
    this.loadDailyReportForStore({
      startDate: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      endDate: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      calculateTotal: false,
    })
    if (this.getDailyReport) {
      this.updateChartDataLabels();
    }
    if (this.getDailyReport) {
      this.updateChartDataDataSet();
    }
  },

  methods: {
    ...mapActions(['loadDailyReportForStore']),
    updateChartDataLabels() {
      this.chartData.labels = this.getDailyReport.map((filiale) => {
        return filiale.storeName;
      });
    },
    updateChartDataDataSet() {
      this.chartData.datasets = [
        {
          label: this.$t('EstimatedEarnings'),
          backgroundColor: ['#0071AE'],
          data: this.getDailyReport.map((filiale) => {
            return filiale.numberOfEmployeesMultiplied; // Replace with the actual data property name from getDailyReport object
          }),
        },
        {
          label: this.$t('CurrentEarnings'),
          backgroundColor: ['#FF274F'],
          data: this.getDailyReport.map((filiale) => {
            return filiale.numberOfRoutesAmounts; // Replace with the actual data property name from getDailyReport object
          }),
        },
      ];
    },
  },
};
</script>

<style scoped>
.container-bar-chart {
  padding: 40px 20px 0px 20px;
  width: 100%;
  border: 1px solid;
  border-radius: 30px;
  background-color: #f4f1ed;
}
</style>
