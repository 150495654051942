<template>
  <div>
    <div class="container overview">
      <!-- <button
        v-b-modal.excel-tasks
        class="button"
        style="border-radius: 6px; height: 36px; margin-top: 25px; margin-left: 0px; width:220px"
      >
        <b-icon-plus />
        {{ $t('UploadCSV') }}
      </button> -->
      <BarChart
        :options="reportsDashboard"
        :css-classes="'container-bar-chart'"
        :height="100"
      />
    </div>
    <div style="display: flex; flex-direction: row; justify-content: space-between; max-height: 76px;" />
    <div
      class="container tt"
      style="width:100%; margin-top:50px; background-color: #f4f1ed; border: 1px solid black; border-radius: 20px; display: flex; flex-direction: column; align-items: left; margin-bottom: 35px;"
    >
      <div
        class="all"
        style="margin: 10px; display: flex;
      justify-content: space-between;     align-items: center;"
      >
        <div class="buttons">
          <b-button
            class="buttonTime"
            type="button"
            variant="none"
            style="background-color: #0071AE; color: #ffffff; margin-right: 5px"
            @click="
              daily()
            "
          >
            {{ $t("Daily") }}
          </b-button>
          <b-button
            class="buttonTime"
            type="button"
            variant="none"
            style="background-color: #0071AE; color: #ffffff; margin-right: 5px"
            @click="
              week()
            "
          >
            {{ $t("Week") }}
          </b-button>
          <!-- <b-button
            class="buttonTime"
            type="button"
            variant="none"
            style="background-color: #242F6E; color: #ffffff; margin-right: 5px"
            @click="
              month()
            "
          >
            {{ $t("Month") }}
          </b-button> -->
        </div>
        <div
          class="inputs"
          style="display: flex;
            margin-bottom: 15px;     align-items: end;"
        >
          <b-form-group
            id="input-group-9 date"
            :label="$t('StartingDate')"
            label-for="input-9"
            class=""
            style="margin-right: 3px"
          >
            <date-picker
              v-model="time1"
              type="date"
              value-type="format"
              :placeholder="$t('SelectDate')"
              :format="'DD-MM-YYYY'"
              :lang="lang"
            />
          </b-form-group>
          <b-form-group
            id="input-group-9 date"
            :label="$t('EndingDate')"
            label-for="input-9"
            class=""
          >
            <date-picker
              v-model="time2"
              type="date"
              value-type="format"
              :placeholder="$t('SelectDate')"
              :format="'DD-MM-YYYY'"
              :lang="lang"
            />
          </b-form-group>
          <b-button
            type="button"
            variant="none"
            class="buttonSubmit searchButton"
            style="color:white;"
            :disabled="this.time1== null || this.time2== null"
            @click="dailyReport(), weeklyReport()"
          >
            {{ $t("Search") }}
          </b-button>
        </div>
      </div>
      <!-- <div
        v-if="!getIsLoading"
      >
        <Spinner />
      </div> -->
      <!-- v-if="!pressedWeek" -->
      <div
        v-if="!pressedWeek"
        style="height: 500px; overflow-y: scroll"
      >
        <table
          responsive
          class="team_table"
          style="width: 100%; position: relative; box-shadow: 0px 7px 20px rgb(40 41 61 / 8%); margin-left: 0%; text-align: center; margin-bottom: 20px; background-color: white;"
        >
          <thead style="position: sticky; top: 0;">
            <tr>
              <th
                style="padding: 10px;"
              >
                {{ $t("Filiale") }}
              </th>
              <th
                style="padding: 10px; "
              >
                {{ $t("No.Employee") }}
              </th>
              <th
                style="padding: 10px; "
              >
                {{ $t("EstimatedEarnings") }}
              </th>
              <th
                style="padding: 10px; "
              >
                {{ $t("CurrentEarnings") }}
              </th>
              <th
                style="padding: 10px; "
              >
                {{ $t("Trucks") }}
              </th>
              <th
                style="padding: 10px;"
              >
                +/-
              </th>
            <!-- <th
                style="padding: 10px; border-radius: 10px 10px 0px 0px"
              >
                Filiale
              </th> -->
            </tr>
          </thead>

          <tbody>
            <template v-for="manager in getDailyTaskReportingForStore.managerTotals">
              <template v-for="store in manager.storeDailyReportings">
                <tr
                  :key="store.storeName"
                  style="height: 34px;
                font-size: 14px;"
                >
                  <td>{{ store.storeName }}</td>
                  <td>{{ store.numberOfEmployees }}</td>
                  <td>{{ store.numberOfEmployeesMultiplied == null ? '0.00':store.numberOfEmployeesMultiplied.toFixed(2) }}CHF</td>
                  <td>{{ store.numberOfRoutesAmounts == null ? '0.00' : store.numberOfRoutesAmounts.toFixed(2) }}CHF</td>
                  <td>{{ store.numberOfTrucks }}</td>
                  <td
                    :style="store.difference > 0 ? 'color: green':'color: red'"
                  >{{ store.difference == null ? '0.00': store.difference.toFixed(2) }}CHF</td>
                </tr>
              </template>
              <tr
                :key="manager.managerId"
                style="background-color: #0071AE;color: white ;height: 34px;
              font-size: 14px;"
              >
                <td>{{ manager.managerName == null ? 'No Manager': manager.managerName }}</td>
                <td>{{ manager.totalEmployees }}</td>
                <td>{{ manager.totalPotencial == null ? '0.00':manager.totalPotencial.toFixed(2) }}CHF</td>
                <td>{{ manager.totalRouteAmounts == null? "0.00":manager.totalRouteAmounts.toFixed(2) }}CHF</td>
                <td>{{ manager.totalTrucks }}</td>
                <td :style="manager.totalDifference > 0 ? 'color: green':'color: red'">
                  {{ manager.totalDifference==null ? '0.00':manager.totalDifference.toFixed(2) }}CHF
                </td>
              </tr>
            </template>

            <tr
              style="background-color: #ffffff;color: white ;height: 34px;
              font-size: 14px;"
            >
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
            </tr>
            <tr
              style="background-color: #ffffff;color: white ;height: 34px;
              font-size: 14px;"
            >
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
            </tr>
            <tr
              style="background-color: #0071AE;color: white ;height: 34px;
              font-size: 14px;"
            >
              <td><strong>Total:</strong></td>
              <td><strong>{{ getDailyTaskReportingForStore.totalEmployees }}</strong></td>
              <td><strong>{{ getDailyTaskReportingForStore.totalPotencial == null ? '0.00':getDailyTaskReportingForStore.totalPotencial.toFixed(2) }}CHF</strong></td>
              <td><strong>{{ getDailyTaskReportingForStore.totalRouteAmounts == null ? '0.00':getDailyTaskReportingForStore.totalRouteAmounts.toFixed(2) }}CHF</strong></td>
              <td><strong>{{ getDailyTaskReportingForStore.totalTrucks }}</strong></td>
              <td
                :style="getDailyTaskReportingForStore.totalDifference > 0 ? 'color: green':'color: red'"
              ><strong>{{ getDailyTaskReportingForStore.totalDifference== null ? '0.00':getDailyTaskReportingForStore.totalDifference.toFixed(2) }}CHF</strong></td>
            <!-- <td><strong>{{ totalEmployees }}</strong></td>
            <td><strong>{{ totalPotencial }}</strong></td>
            <td><strong>{{ totalRouteAmounts }}</strong></td>
            <td><strong>{{ totalTrucks }}</strong></td>
            <td><strong>{{ totalDifference }}</strong></td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div
      v-if="pressedWeek"
      style="width: 100%; margin-top: -3px ;    display: flex;
      flex-wrap: wrap;
      justify-content: center; height: 700px; overflow-y: scroll;"
    >
      <div
        v-for="item in getWeekTaskReportingForStore"
        :key="item.id"
        style="position: relative;"
      >
        <hr style="color: black">
        <p style="text-align: center; margin: 10px 0; font-weight: bold; position: sticky; top: 0; background-color: white;">
          {{ dateModified(item.date) }}
        </p>
        <table

          responsive
          class="team_table"
          style="width: 90%; box-shadow: 0px 7px 20px rgb(40 41 61 / 8%); margin-left: 4%; text-align: center;"
        >
          <thead>
            <tr>
              <th
                style="padding: 10px; "
              >
                {{ $t("Filiale") }}
              </th>
              <th
                style="padding: 10px; "
              >
                {{ $t("No.Employee") }}
              </th>
              <th
                style="padding: 10px; "
              >
                {{ $t("EstimatedEarnings") }}
              </th>
              <th
                style="padding: 10px; "
              >
                {{ $t("CurrentEarnings") }}
              </th>
              <th
                style="padding: 10px; "
              >
                {{ $t("Trucks") }}
              </th>
              <th
                style="padding: 10px; "
              >
                {{ $t("Difference") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="manager in item.dailyReporting.managerTotals">
              <template v-for="store in manager.storeDailyReportings">
                <tr
                  :key="store.storeName"
                  style="height: 34px;
                  font-size: 14px;"
                >
                  <td>{{ store.storeName }}</td>
                  <td>{{ store.numberOfEmployees }}</td>
                  <td>{{ store.numberOfEmployeesMultiplied.toFixed(2) }}CHF</td>
                  <td>{{ store.numberOfRoutesAmounts == null ? '0.00' : store.numberOfRoutesAmounts.toFixed(2) }}CHF</td>
                  <td>{{ store.numberOfTrucks }}</td>
                  <td
                    :style="store.difference > 0 ? 'color: green':'color: red'"
                  >{{ store.difference == null ? '0.00': store.difference.toFixed(2) }}CHF
                  </td></tr>
              </template>
              <tr
                :key="manager.managerId"
                style="background-color: #0071AE;color: white ;height: 34px;
                font-size: 14px;"
              >
                <td>{{ manager.managerName == null ? 'No Manager': manager.managerName }}</td>
                <td>{{ manager.totalEmployees }}</td>
                <td>{{ manager.totalPotencial.toFixed(2) }}CHF</td>
                <td>{{ manager.totalRouteAmounts.toFixed(2) }}CHF</td>
                <td>{{ manager.totalTrucks }}</td>
                <td
                  :style="manager.totalDifference > 0 ? 'color: green':'color: red'"
                >{{ manager.totalDifference.toFixed(2) }}CHF</td>
              </tr>
            </template>

            <tr
              style="background-color: #ffffff;color: white ;height: 34px;
              font-size: 14px;"
            >
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
            </tr>
            <tr
              style="background-color: #ffffff;color: white ;height: 34px;
              font-size: 14px;"
            >
              <td />
              <td />
              <td />
              <td />
              <td />
              <td />
            </tr>

            <tr
              style="background-color: #0071AE;color: white ;height: 34px;
              font-size: 14px;"
            >
              <td><strong>Total:</strong></td>
              <td><strong>{{ item.dailyReporting.totalEmployees }}</strong></td>
              <td><strong>{{ item.dailyReporting.totalPotencial.toFixed(2) }}CHF</strong></td>
              <td><strong>{{ item.dailyReporting.totalRouteAmounts.toFixed(2) }}CHF</strong></td>
              <td><strong>{{ item.dailyReporting.totalTrucks }}</strong></td>
              <td
                :style="item.dailyReporting.totalDifference > 0 ? 'color: green':'color: red'"
              ><strong>{{ item.dailyReporting.totalDifference.toFixed(2) }}CHF</strong></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <ExcelTasksModal />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// eslint-disable-next-line no-unused-vars
// import AddRouteModal from '@/components/routes/modals/AddRouteModal.vue';
// import EditRouteModal from '@/components/routes/modals/EditRouteModal.vue';
import ExcelTasksModal from '@/components/ExcelTasksModal.vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment-timezone'
import BarChart from './Charts/barChartForTask.vue'
// import Spinner from './Spinner/Spinner.vue'

export default {
  components: {
    DatePicker,
    ExcelTasksModal,
    BarChart,
    // Spinner,
  },
  // eslint-disable-next-line vue/require-prop-types
  data() {
    return {

      initialId: '',
      pageSize: 15,
      pageNumber: 1,
      loading: false,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
      test: [],
      storeGLN: null,
      requestData: null,
      status: true,
      totalEmployees: 0,
      totalPotencial: 0,
      totalRouteAmounts: 0,
      totalTrucks: 0,
      totalDifference: 0,
      employee: [],
      potencial: [],
      routeAmount: [],
      trucks: [],
      difference: [],
      pressedWeek: false,
      editFilialeName: '',
      time1: null,
      time2: null,
      store: 'ALL',
      route: {
        tourId: '',
        name: '',
        storeGLN: '',
        startDate: '',
        endDate: '',
        amount: '',
      },
      pageP: 1,
      pageC: 1,
      classNameCh: '',
      selectedDate: moment().format('DD-MM-YYYY'),
      // selectedDate: '',
      searchTerm: '',
      rows: [],
      materialObject: {},
      editMat: {},
      color: {
        mainDressCategoryColorId: '',
        name: '',
      },
      getFiliale: {
        filialeName: 'BE-ZU',
        address: 'Bern',
        todayRoutes: '17/12/22',
        trucks: '18/12/22',
        totalEmployee: '08:00 AM - 4:00 PM',
      },
    };
  },

  computed: {
    ...mapGetters(['getOrdersGrouppedByState',
      'getLoggedInUser',
      'getIsLoading',
      'getStoreMainboardOverview',
      'getFiliales',
      'getLoggedInUser',
      'getDailyReport',
      'getDailyTaskReportingForStore',
      'getWeekTaskReportingForStore',
      'getWeeklyReport', 'getRoutesWithPagination', 'getRoutesWithPagination', 'getTotalItemsForRoutes', 'getFiliales', 'getTotalItemsForFiliales', 'getFilialesForRoutes']),
    reportsDashboard() {
      const monthlyReports = this.getDailyReport;
      const sortedReports = monthlyReports.sort((a, b) => { return a.storeName.localeCompare(b.storeName) });
      return sortedReports;
    },
    totalsPerWeek() {
      const summedValues = {};

      for (let i = 0; i < this.getWeekTaskReportingForStore.length; i++) {
        const date = this.getWeekTaskReportingForStore[i].date;

        for (let j = 0; j < this.getWeekTaskReportingForStore[i].dailyReporting.length; j++) {
          const dailyReport = this.getWeekTaskReportingForStore[i].dailyReporting[j];

          const {
            totalPotencial,
            totalRouteAmounts,
            totalTrucks,
            totalEmployees,
            totalDifference,
          } = dailyReport;

          if (Object.prototype.hasOwnProperty.call(summedValues, date)) {
            summedValues[date].potencial += totalPotencial;
            summedValues[date].routeAmount += totalRouteAmounts;
            summedValues[date].trucks += totalTrucks;
            summedValues[date].employee += totalEmployees;
            summedValues[date].difference += totalDifference;
          } else {
            summedValues[date] = {
              potencial: totalPotencial,
              routeAmount: totalRouteAmounts,
              trucks: totalTrucks,
              employee: totalEmployees,
              difference: totalDifference,
            };
          }
        }
      }

      // console.log(summedValues);
      // this.test = summedValues
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.test.push(summedValues)
      return summedValues
    },
    // totalEmployees2() {
    //   let temp = 0;
    //   this.getWeekTaskReportingForStore.dailyReporting.forEach((value) => {
    //     temp += value.totalEmployees;
    //   });

    //   return temp;
    // },
    // totalPotencial2() {
    //   return this.getWeekTaskReportingForStore.dailyReporting.reduce(
    //     (total, manager) => { return total + manager.totalPotencial },
    //     0,
    //   );
    // },
    // totalRouteAmounts2() {
    //   return this.getWeekTaskReportingForStore.dailyReporting.reduce(
    //     (total, manager) => { return total + manager.totalRouteAmounts },
    //     0,
    //   );
    // },
    // totalTrucks2() {
    //   return this.getWeekTaskReportingForStore.dailyReporting.reduce(
    //     (total, manager) => { return total + manager.totalTrucks },
    //     0,
    //   );
    // },
    // totalDifference2() {
    //   return this.getWeekTaskReportingForStore.dailyReporting.reduce(
    //     (total, manager) => { return total + manager.totalDifference },
    //     0,
    //   );
    // },
  },
  watch: {
    // eslint-disable-next-line func-names
    pageNumber(value) {
      this.loadRouteByPagination({
        storeGLN: null,
        startShift: this.selectedDate,
        endShift: null,
        tourName: null,
        pageNumber: value,
        pageSize: this.pageSize,
      });
    },
    time1(value) {
      const res = moment.tz.guess();
      const value2 = moment.utc(value, 'DD-MM-yyyyTHH:mm:ss');

      // Check if selected date is a weekend day
      if (moment(value2).isoWeekday() === 6) { // Saturday
        value2.add(2, 'days'); // Move to next Monday
      } else if (moment(value2).isoWeekday() === 7) { // Sunday
        value2.add(1, 'days'); // Move to next Monday
      }

      this.startDate = moment.tz(value2, 'DD-MM-yyyyTHH:mm:ss', res).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      this.startDate = this.startDate.replace(/\+/, '%2B');
    },
    time2(value) {
      const res = moment.tz.guess();
      const value2 = moment.utc(value, 'DD-MM-yyyyTHH:mm:ss').set({ hour: 23, minute: 55 });

      // Check if selected date is a weekend day
      if (moment(value2).isoWeekday() === 6) { // Saturday
        value2.add(2, 'days'); // Move to next Monday
      } else if (moment(value2).isoWeekday() === 7) { // Sunday
        value2.add(1, 'days'); // Move to next Monday
      }

      this.endDate = moment.tz(value2, 'DD-MM-yyyyTHH:mm:ss', res).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      this.endDate = this.endDate.replace(/\+/, '%2B');
    },
    // selectedDate: {
    //   handler(value) {
    //     this.requestData = {
    //       ...this.requestData,
    //       storeGLN: this.store.storeGLN,
    //       startShift: value,
    //     };
    //     this.makeRequest();
    //   },
    //   immediate: true,
    // },
    // store: {
    //   handler(value) {
    //     this.requestData = {
    //       ...this.requestData,
    //       storeGLN: value.storeGLN,
    //       startShift: this.selectedDate,
    //     };
    //     this.makeRequest();
    //   },
    //   immediate: true,
    // },
  },
  mounted() {
    // this.fetchRoutes();
    // this.fetchFilialeForTrucks();
    // this.storeMainboardStats();
    this.changeLoadingtoTrue(false);

    this.loadDailyTaskReportingForStore({
      startDate: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      endDate: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
    }).then(() => {
      this.changeLoadingtoTrue(true);
    });
    // this.calculateTotals();
    // this.calculateTotalss();
    this.changeLoadingtoTrue(false);

    this.loadWeeklyTaskReportingForStore({
      startDate: moment().startOf('isoWeek').isoWeekday(1).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      endDate: moment()
        .isoWeekday(6)
        .endOf('day')
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),


      // startDate: moment().startOf('isoWeek').isoWeekday(1).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      // endDate: moment().endOf('isoWeek').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
    }).then(() => {
      this.changeLoadingtoTrue(true);
    });
    this.changeLoadingtoTrue(false);


    // this.totalsPerWeek()


    // Initialize an empty object to store the summed values for each date
  },

  methods: {
    ...mapActions([
      'addTours',
      'loadFilialePagination',
      'loadRouteByPagination',
      'loadFilialePaginationForRoutes',
      'editRoute',
      'loadDailyReportForStore', 'changeLoadingtoTrue',
      'resetOrdersOverview',
      'loadFilialePagination',
      'loadStoreMainboardOverview',
      'loadUpComingVacations',
      'loadDailyReportForStore',
      'loadDailyTaskReportingForStore',
      'loadWeeklyTaskReportingForStore',
    ]),
    // calculateTotals() {
    //   this.totalEmployees = 0;
    //   this.totalPotencial = 0;
    //   this.totalRouteAmounts = 0;
    //   this.totalTrucks = 0;
    //   this.totalDifference = 0;

    //   // eslint-disable-next-line no-restricted-syntax
    //   for (const manager of this.getDailyTaskReportingForStore) {
    //     this.totalEmployees += manager.totalEmployees;
    //     this.totalPotencial += manager.totalPotencial;
    //     this.totalRouteAmounts += manager.totalRouteAmounts;
    //     this.totalTrucks += manager.totalTrucks;
    //     this.totalDifference += manager.totalDifference;
    //   }
    // },



    storeMainboardStats() {
      // this.loadStoreMainboardOverview({
      //   storeGLN: null,
      //   // startDate: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
      //   // endDate: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
      //   startDate: moment().startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      //   endDate: moment().endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      // });
    },
    dateModified(xdate) {
      return moment(xdate).format('dddd, DD/MM/YYYY');
    },
    async dailyReport() {
      try {
        await this.loadDailyReportForStore({
          startDate: this.startDate,
          endDate: this.endDate,
          calculateTotal: false,

        })
      } catch (e) {
        console.log(e);
      }
    },
    async weeklyReport() {
      this.pressedWeek = true
      try {
        await this.loadWeeklyTaskReportingForStore({
          startDate: this.startDate,
          endDate: this.endDate,

        })
      } catch (e) {
        console.log(e);
      }
    },
    async daily() {
      this.time1 = ''
      this.time2 = ''
      this.pressedWeek = false
      this.changeLoadingtoTrue(false);


      const temp1 = moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      const temp2 = moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      try {
        await this.loadDailyReportForStore({
          startDate: temp1,
          endDate: temp2,
          calculateTotal: false,
        }).then(() => {
          this.changeLoadingtoTrue(true);
        });
      } catch (e) {
        console.log(e);
        this.changeLoadingtoTrue(true);
      }
    },
    async week() {
      this.time1 = moment().startOf('isoWeek').isoWeekday(1).format('DD-MM-YYYY');
      this.time2 = moment().isoWeekday(6).endOf('day').format('DD-MM-YYYY');
      this.changeLoadingtoTrue(false);

      this.pressedWeek = true
      try {
        await this.loadDailyReportForStore({
          startDate: moment().startOf('isoWeek').isoWeekday(1).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          endDate: moment().endOf('isoWeek').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          calculateTotal: false,
        }).then(() => {
          this.changeLoadingtoTrue(true);
        });
      } catch (e) {
        console.log(e);
        this.changeLoadingtoTrue(false);
      }
      try {
        await this.loadWeeklyTaskReportingForStore({
          startDate: moment().startOf('isoWeek').isoWeekday(1).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          endDate: moment()
            .isoWeekday(6)
            .endOf('day')
            .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),

        }).then(() => {
          this.changeLoadingtoTrue(true);
        });
      } catch (e) {
        console.log(e);
        this.changeLoadingtoTrue(false);
      }
    },
    // async month() {
    //   this.time1 = ''
    //   this.time2 = ''

    //   this.pressedWeek = false

    //   try {
    //     await this.loadDailyReportForStore({
    //       startDate: moment().startOf('month').date(1)
    //         .format('YYYY-MM-DDT00:00:00.000[Z]'),
    //       endDate: moment().endOf('month').format('YYYY-MM-DDT23:59:59.999[Z]'),
    //       calculateTotal: false,

    //     })
    //   } catch (e) {
    //     console.log(e);
    //   }
    // },

  },
};
</script>

<style scoped lang="scss">

.search-toggle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

span{
  color: black;
}
p{
  color: black;
}
// table td{
//   color: black;
// }
.year {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  button {
    width: 36px !important;
    height: 36px;
    display: flex;
    margin-right: 5px;
    border-radius: 6px !important;
    justify-content: center;
    border: #c3d0dd 1px solid;
    background-color: #e9ecef;
    align-items: center;
  }
  h5 {
    border: #b4b4b4 2px solid;
    border-radius: 6px;
    background-color: whitesmoke;
    width: 40%;
    height: 36px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }
}
.button {
  margin-bottom: 15px !important;
  width: 195px;
}
.mat {
  display: flex;
  justify-content: space-between;
  align-content: center;
  &.active {
    background-color: #e8e8e8;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  span {
    margin-left: 10px;
    svg {
      font-size: 15px;
    }
  }
}
.team_table {
  font-size: 12px;
  border-radius: 8px 8px 8px 8px !important;
}

tr:last-child {
  border-bottom: none !important;
}
@media screen and (max-width: 1823px) {
  .color-listing__wrapper2 {
    width: 200px;
    button {
      width: 90%;
    }
    .team_table {
      width: 150px !important;
    }
  }
}
@media screen and (max-width: 1400px) {
  .color-listing__wrapper {
    margin-left: 50px;
    width: 150px !important;
    .team_table {
      width: 150px !important;
    }
  }
  .color-listing__wrapper2 {
    margin-left: 10px;
    width: 200px !important;
    button {
      width: 150px;
      font-size: 13px;
      height: 36px;
    }
    .team_table {
      width: 100% !important;
    }
  }
}
@media screen and (max-width: 896px) {
  .color-listing__wrapper {
    margin-left: 0 !important;
    flex-basis: 49%;
  }
}
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.flex-form {
  display: flex;
  flex-direction: column;
}

.pagination {
  display: flex !important;
  width: 100%;
  margin: 0.25rem 0.25rem 0 !important;
}
</style>
